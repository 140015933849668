export function jsonStringify (obj: any, customImage: boolean = false) {
  // Base case for null
  if (obj === null) {
    return 'null'
  }

  // Base case for undefined
  if (obj === undefined) {
    return 'undefined'
  }

  // Objects and Arrays need recursion
  if (typeof obj === 'object') {
    if (Array.isArray(obj)) {
      // Array
      let str = '['
      str += obj.map(value => jsonStringify(value)).join(',')
      str += ']'
      return str
    } else {
      // Object
      let str = '{'

      let entries = Object.entries(obj)

      if (entries.length === 0) {
        entries = stringifyFileObject(obj, customImage)
        entries = Object.entries(entries)
      }

      str += entries.map((entry) => {
        return `"${entry[0]}":${jsonStringify(entry[1])}`
      }).join(',')
      str += '}'
      return str
    }
  }

  // Primitives just need converting to a string
  if (typeof obj === 'string') {
    return `"${obj}"`
  }
  if (typeof obj === 'number' || typeof obj === 'boolean') {
    return obj.toString()
  }
}

function stringifyFileObject (file, customImage) {
  let objectData = null
  if (!customImage) {
    const obj = {
      lastModified: file?.lastModified,
      name: file?.name,
      size: file?.size,
      type: file?.type,
      webkitRelativePath: file?.webkitRelativePath
    }
    objectData = obj
  } else {
    const obj = {
      id: file?.id,
      key: file?.key,
      name: file?.name,
      size: file?.size,
      url: file?.url
    }
    objectData = obj
  }

  return objectData
}

export async function createFileFromBase64 (base64Data: string, contentType: string, name: string) {
  const response = await fetch(base64Data)
  const blob = await response.blob()
  const FileFormBase64 = new File([blob], name, { type: contentType })
  return FileFormBase64
}

export function removeDraftAction () {
  localStorage.removeItem('draft')
  localStorage.removeItem('draftQuery')
  localStorage.removeItem('draftModalBackgroundImageSelected')
  localStorage.removeItem('draftModalLogoImageSelected')
  localStorage.removeItem('customModalBackgroundImage')
  localStorage.removeItem('customModalLogoFileImage')

  localStorage.removeItem('draftAvatarSelected')
  localStorage.removeItem('draftAvatarImage')
  localStorage.removeItem('customAvatarImage')
}

export function createImageBase64ForLocalStorage (name: string, value: any) {
  const fileReader = new FileReader()
  fileReader.readAsDataURL(value)
  fileReader.addEventListener('load', () => {
    const url = fileReader.result
    localStorage.setItem(name, String(url))
  })
}
